import { createMemo } from "solid-js";
import { Patient } from "../patient";
import { floatRounding } from "../utils";
import { CellTags } from "./uiKit/table/CellTags";
import { HeaderSelectFilter } from "./uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "./uiKit/table/HeaderSort";
import { Table } from "./uiKit/table/Table";

export function AlertsTable(props: { patient: Patient }) {
  const model = createMemo(() => props.patient.alerts);

  return (
    <Table
      model={model()}
      columns={[
        {
          header1: "Timestamp",
          header2: <HeaderSort attr="alert_timestamp" model={model()} />,
          cell: (item) => item.alert_timestamp,
        },
        {
          header1: "Variable",
          header2: (
            <HeaderSelectFilter
              attr="alert_variable"
              model={model()}
              name="Variable"
            />
          ),
          cell: (item) => item.alert_variable,
        },
        {
          header1: "Type",
          header2: (
            <HeaderSelectFilter
              attr="alert_type"
              model={model()}
              name="Type"
              values={model().alertTypes}
            />
          ),
          cell: (item) => model().alertTypes[item.alert_type],
        },
        {
          header1: "Value",
          cell: (item) => floatRounding(item.alert_value, 1),
        },
        {
          header1: "Reason",
          cell: (item) => item.alert_reason,
        },
        {
          header1: "Tags",
          header2: (
            <HeaderSelectFilter attr="alert_tags" model={model()} name="Tags" />
          ),
          cell: (item) => <CellTags tags={item.alert_tags} />,
        },
      ]}
    />
  );
}
