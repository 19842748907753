import { createMemo, For, Show } from "solid-js";
import { ApiConnector } from "../apiConnector";
import { ppgConditions, ppgMeasModes, ppgQuality } from "../enumToText";
import { Patient } from "../patient";
import { PPGQuickLook } from "../quickLookAnalyses";
import { floatRounding } from "../utils";
import { CellEditButtons } from "./uiKit/table/CellEditButtons";
import { CellSelectEditable } from "./uiKit/table/CellSelectEditable";
import { CellTextEditable } from "./uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "./uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "./uiKit/table/HeaderSort";
import { Table } from "./uiKit/table/Table";

export function PPGTable(props: {
  patient: Patient;
  api: ApiConnector;
  ppgQuickLook: PPGQuickLook;
}) {
  const model = createMemo(() => props.patient.ppgs);

  return (
    <>
      <Table
        model={model()}
        columns={[
          {
            header1: "Measured",
            header2: <HeaderSort attr="measurement_datetime" model={model()} />,
            cell: (item) => item.measurement_datetime,
          },
          {
            header1: "Condition",
            header2: (
              <HeaderSelectFilter
                attr="measurement_condition"
                model={model()}
                name="Condition"
              />
            ),
            cell: (item, index) => (
              <CellSelectEditable
                attr="measurement_condition"
                index={index}
                model={model()}
                values={ppgConditions}
              />
            ),
          },
          {
            header1: "Device",
            header2: (
              <HeaderSelectFilter
                attr="measurement_device"
                model={model()}
                name="Device"
              />
            ),
            cell: (item) => item.measurement_device,
          },
          {
            header1: "Origin",
            header2: (
              <HeaderSelectFilter
                attr="measurement_origin"
                model={model()}
                name="Origin"
              />
            ),
            cell: (item) => item.measurement_origin,
          },
          {
            header1: "Length",
            cell: (item) => floatRounding(item.length, 1),
          },
          {
            header1: "Mode",
            header2: (
              <HeaderSelectFilter
                attr="measurement_mode"
                model={model()}
                name="Mode"
                values={ppgMeasModes}
              />
            ),
            cell: (item, index) => (
              <CellSelectEditable
                attr="measurement_mode"
                index={index}
                model={model()}
                values={ppgMeasModes}
              />
            ),
          },
          {
            header1: "Quality",
            header2: (
              <HeaderSelectFilter
                attr="quality_flag"
                model={model()}
                name="Quality"
                values={ppgQuality}
              />
            ),
            cell: (item, index) => (
              <CellSelectEditable
                attr="quality_flag"
                index={index}
                model={model()}
                values={ppgQuality}
              />
            ),
          },
          {
            header1: "SQI",
            cell: (item) => floatRounding(item.computed_sqi, 1),
          },
          props.api.isProdEnv && {
            header1: "LabelStudio",
            cell: (item) => (
              <button
                onClick={() => {
                  props.patient.redirectToLabelStudio(item.uuid);
                }}
              >
                Redirect
              </button>
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellTextEditable
                attr="comment"
                index={index}
                model={model()}
                width={50}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.patient.updateItem("ppg", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.amILevel3 && {
            header1: (
              <>
                <em>QuickLook</em> analysis
              </>
            ),
            cell: (item) => (
              <input
                type="checkbox"
                checked={model().toQuickLook.includes(item.uuid)}
                onChange={() => {
                  model().toggleQuickLook(item.uuid);
                  props.ppgQuickLook.reset();
                }}
              />
            ),
          },
        ]}
        rowsExtra={
          <>
            <Show when={props.api.amILevel3}>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td>
                  <em>QuickLook</em> Analysis:
                </td>
                <td>
                  <select
                    value={props.ppgQuickLook.analysisType ?? ""}
                    onChange={(event) => {
                      props.ppgQuickLook.analysisType = event.target.value;
                      props.ppgQuickLook.reset();
                    }}
                  >
                    <option value="" disabled selected>
                      Select type...
                    </option>
                    <For
                      each={Object.entries(props.ppgQuickLook.quickLookTypes)}
                    >
                      {([key, option]) => (
                        <option value={key}>{option as string}</option>
                      )}
                    </For>
                  </select>
                </td>
                <td>
                  <button
                    disabled={
                      props.ppgQuickLook.processing ||
                      model().toQuickLook.length === 0 ||
                      !props.ppgQuickLook.analysisType ||
                      !props.patient.consentOk()
                    }
                    onClick={() => {
                      if (props.ppgQuickLook.resultUrl) {
                        window.open(props.ppgQuickLook.resultUrl, "_blank");
                      } else {
                        props.ppgQuickLook.submit(model().toQuickLook);
                      }
                    }}
                    class="quicklook-button"
                  >
                    <Show
                      when={props.ppgQuickLook.processing}
                      fallback={props.ppgQuickLook.buttonText}
                    >
                      <img
                        src="/static/imgs/sq_spin.png"
                        class="spinner"
                        style="width: 20px; height: 20px"
                      />
                    </Show>
                  </button>
                </td>
              </tr>
            </Show>

            <Show when={props.ppgQuickLook.resultUrl}>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td style="text-align: center">
                  <button
                    onClick={() => {
                      props.ppgQuickLook.shareQuickLook();
                    }}
                  >
                    Share analysis...
                  </button>
                </td>
              </tr>
            </Show>
          </>
        }
      />

      <p>Note for PPG quality flags:</p>
      <table class="info-table">
        <tbody>
          <tr>
            <td>
              <strong>Data error:</strong>
            </td>
            <td>
              Completely bad signal, should not be used under any circumstances;
              this flag is marked automatically when computed{" "}
              <strong>SQI is &lt;= 3.0</strong>;<br />
              <span class="text-red">
                manual changes to the PPG flag to <em>data error</em> will cause
                all related data points (Seerlinq-computed data and PPG-derived)
                to change their flag to <em>Wrong</em>
              </span>
              ;<br />
              PPG data with <em>data error</em> flag are{" "}
              <strong>not used in any automatic algorithms</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>OK:</strong>
            </td>
            <td>Signal is OK to use for analyses</td>
          </tr>
          <tr>
            <td>
              <strong>Low signal quality:</strong>
            </td>
            <td>
              Signal is not perfect, some weirdness happening, need manual check
              / preprocessing; this flag is marked automatically when computed{" "}
              <strong>SQI is between 3.0 and 5.0</strong>;<br />
              <span class="text-red">
                manual changes to the PPG flag to <em>low signal quality</em>{" "}
                will cause all related data points (Seerlinq-computed data and
                PPG-derived) to change their flag to <em>Low PPG Quality</em>
              </span>
              ;<br />
              PPG data with <em>low signal quality</em> flag are used in
              automatic algorithms,{" "}
              <strong>
                but the resulting Seerlinq-computed data instance (like DRI)
                automatically inherits the <em>Low PPG Quality</em> flag.
              </strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Artefacts:</strong>
            </td>
            <td>
              Signal contains a lot of artefacts, it is not OK to use as is
            </td>
          </tr>
          <tr>
            <td>
              <strong>Short:</strong>
            </td>
            <td>
              Signal is short, maybe recording by accident, will not be used
            </td>
          </tr>
          <tr>
            <td>
              <strong>Extra:</strong>
            </td>
            <td>
              Additional circumstance that may be important, data will be
              treated with care, please put additional info to the comment
              (e.g., gel nails, or pacemaker)
            </td>
          </tr>
          <tr>
            <td>
              <strong>Testing:</strong>
            </td>
            <td>
              Signal is used for testing, will not be used or included in any
              analysis
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
