import * as Sentry from "@sentry/browser";
import {
  apiGetHfBasicMedDataPatientId,
  apiGetHfComorbidities,
  apiGetHfComorbiditiesPatientId,
  apiGetHfExamsPatientId,
  apiGetHfLabDataPatientId,
  apiGetHfSymptomsPatientId,
  apiGetHfVitalsPatientId,
  apiGetLabelstudioTaskProjectIdPpgUuid,
  apiGetMessagingPatientId,
  apiGetPatientsPatientId,
  apiGetPatientsPatientIdAlerts,
  apiGetPatientsPatientIdComputed,
  apiGetPatientsPatientIdDerived,
  apiGetPatientsPatientIdHealthPro,
  apiGetPatientsPatientIdMedications,
  apiGetPatientsPatientIdPpgs,
  apiGetSchedules,
  apiGetThresholdsDefault,
  apiPostPatientsPatientIdUser,
  apiPutPatientsPatientId,
  client,
} from "./api";
import { ApiConnector } from "./apiConnector";
import { labelStudioProjectId } from "./config";
import { Alerts } from "./dataModels/Alerts";
import { Computed } from "./dataModels/Computed";
import { DerivedData } from "./dataModels/DerivedData";
import { Diagnoses } from "./dataModels/Diagnoses";
import { Events } from "./dataModels/Events";
import { MedData } from "./dataModels/MedData";
import { Medications } from "./dataModels/Medications";
import { MergedMedData } from "./dataModels/MergedMedData";
import { PPGs } from "./dataModels/PPGs";
import { Schedules } from "./dataModels/Schedules";
import { Symptoms } from "./dataModels/Symptoms";
import { Thresholds } from "./dataModels/Thresholds";
import { consentOk, isPaperConsent } from "./dataUtils";
import { ComputedPlot, MedicalPlot, SymptomPlot } from "./plotting";
import { dateTimeISOString, dateTimeOrNull, floatRounding } from "./utils";

export class Patient {
  patientId: number = null;
  loaded = false;
  data: any = null;
  alerts: Alerts = null;
  vitals: MergedMedData = null;
  symptoms: Symptoms = null;
  events: Events = null;
  medications: Medications = null;
  diags: Diagnoses = null;
  labs: MedData = null;
  exams: MedData = null;
  basicMed: MedData = null;
  ppgs: PPGs = null;
  schedules: Schedules = null;
  physicianMessage: any = null;
  heartCore: any = null;

  diagOptions: Record<string, string[]> = {};
  minDRIHeartCore = 4;

  driData: Computed;
  driDataPlot: ComputedPlot;
  vitalsPlot: MedicalPlot;
  symptomPlot: SymptomPlot;
  thresholds: Thresholds;
  labsPlot: MedicalPlot;
  basicMedPlot: MedicalPlot;

  constructor(public api: ApiConnector) {}

  async fetchBasic(patientId: number) {
    this.patientId = patientId;
    await this.api.checkLoggedIn();
    this.data = (
      await apiGetPatientsPatientId({
        path: { patient_id: patientId },
        throwOnError: true,
      })
    ).data;
  }

  async fetch(patientId: number) {
    this.patientId = patientId;
    await this.api.checkLoggedIn();

    this.data = (
      await apiGetPatientsPatientId({
        path: { patient_id: patientId },
        throwOnError: true,
      })
    ).data;

    try {
      await Promise.all([
        this.fetchAlerts(),
        this.fetchDri(),
        this.fetchVitals(),
        this.fetchSymptoms(),
        this.fetchEventsAndThresholds(),
        this.fetchMedications(),
        this.fetchComorbidities(),
        this.fetchLabs(),
        this.fetchExams(),
        this.fetchBasicMedData(),
        this.fetchPpgData(),
        this.fetchSchedules(),
        this.fetchPhysicianMessage(),
        this.fetchHeartCore(),
      ]);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }

    this.loaded = true;
  }

  consentOk() {
    return consentOk(this.data);
  }

  isPaperConsent() {
    return isPaperConsent(this.data);
  }

  sanitizeBody(body: object, datetimeIso: string[] = []) {
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        if (body[key] === "") {
          body[key] = null;
        }
      }
      if (datetimeIso.includes(key) && body.hasOwnProperty(key)) {
        var datetime = body[key];
        body[key] = dateTimeISOString(datetime);
      }
    }
    // remove null
    body = Object.fromEntries(
      Object.entries(body).filter(([key, value]) => value !== null),
    );
    return body;
  }

  async updatePatientField(body: object) {
    const cleanBody = this.sanitizeBody(body);
    await apiPutPatientsPatientId({
      path: { patient_id: this.patientId },
      body: cleanBody,
      throwOnError: true,
    });
    await this.fetch(this.patientId);
  }

  async updateItem(
    endpoint: string,
    uuid: string,
    body: object,
    datetimeIso: string[] = [],
  ) {
    const cleanBody: any = this.sanitizeBody(body, datetimeIso);
    await client.put({
      url: `/api/v1/${endpoint}/${uuid}`,
      body: cleanBody,
      throwOnError: true,
    });
    this.loaded = false;
    await this.fetch(this.patientId);
    this.loaded = true;
  }

  async deleteItem(endpoint: string, uuid: string) {
    await client.delete({
      url: `/api/v1/${endpoint}/${uuid}`,
      throwOnError: true,
    });
    this.loaded = false;
    await this.fetch(this.patientId);
    this.loaded = true;
  }

  async createConnectedUser(body: any) {
    await apiPostPatientsPatientIdUser({
      path: { patient_id: this.patientId },
      body,
      throwOnError: true,
    });
    this.loaded = false;
    await this.fetch(this.patientId);
    this.loaded = true;
  }

  async generateHealthProReports(maxReports: number, fromDate: string) {
    const reports = await apiGetPatientsPatientIdHealthPro({
      path: { patient_id: this.patientId },
      query: {
        max_reports: maxReports,
        from_date: fromDate === null ? null : fromDate,
        throwOnError: true,
      },
    });
    var reportsData = reports.data;
    reportsData.from_date = dateTimeOrNull(reportsData.from_date, false);
    reportsData.from_date =
      reportsData.from_date === null ? "--" : reportsData.from_date;
    return reportsData;
  }

  numDRIDataPoints() {
    return this.driData.data.length;
  }

  floatRounding = floatRounding;

  private async fetchAlerts() {
    if (!this.api.amILevel3) {
      return;
    }

    const alerts = await apiGetPatientsPatientIdAlerts({
      path: { patient_id: this.patientId, throwOnError: true },
    });
    this.alerts = new Alerts(alerts.data.alerts);
    this.alerts.init();
  }

  private async fetchDri() {
    const allComputed = await apiGetPatientsPatientIdComputed({
      path: { patient_id: this.patientId },
      query: { all_versions: true },
      throwOnError: true,
    });
    this.driData = new Computed(allComputed.data.computed_data);
    this.driData.init();
    this.driData.filterDRI();
    this.driDataPlot = new ComputedPlot(
      this.driData,
      ["diastolic reserve index"],
      "driDataPlot",
    );
    this.driDataPlot.initPlot();
  }

  private async fetchVitals() {
    const vitals = await apiGetHfVitalsPatientId({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.vitals = new MergedMedData(
      vitals.data.medical_data,
      this.api.amILevel3,
    );
    // merge with SpO2 and HR from derived and HR from computed
    const derived = await apiGetPatientsPatientIdDerived({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    const SpO2 = new DerivedData(derived.data.derived_data);
    SpO2.init();
    SpO2.filterSpO2();
    const HRDerived = new DerivedData(derived.data.derived_data);
    HRDerived.init();
    HRDerived.filterHR();

    const computed = await apiGetPatientsPatientIdComputed({
      path: { patient_id: this.patientId },
      query: { all_versions: false },
      throwOnError: true,
    });
    const HRComputed = new Computed(computed.data.computed_data);
    HRComputed.init();
    HRComputed.filterHR();

    const varMapping = {
      "SpO2: mean": "SpO2",
      "HR: mean": "heart rate",
      "heart rate": "heart rate",
    };
    this.vitals.mergeWithPPGDerivedAndComputed(
      [SpO2, HRDerived],
      [HRComputed],
      "measurement_type",
      varMapping,
    );
    this.vitals.init();
    this.vitalsPlot = new MedicalPlot(
      this.vitals,
      [
        "heart rate",
        "blood pressure diastolic",
        "blood pressure systolic",
        "SpO2",
      ],
      "vitalsPlot",
    );
    this.vitalsPlot.initPlot();
  }

  private async fetchSymptoms() {
    const symptoms = await apiGetHfSymptomsPatientId({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.symptoms = new Symptoms(symptoms.data.symptoms, this.api.amILevel3);
    this.symptoms.init();
    this.symptomPlot = new SymptomPlot(
      this.symptoms,
      ["shortness of breath", "fatigue score"],
      "symptomPlot",
    );
    this.symptomPlot.initPlot();
  }

  private async fetchEventsAndThresholds() {
    if (!this.api.amILevel3) {
      return;
    }

    this.events = new Events(this.data.events);
    this.events.init();
    const defaultThresholds = await apiGetThresholdsDefault({
      throwOnError: true,
    });
    this.thresholds = new Thresholds(
      this.data.thresholds,
      defaultThresholds.data.thresholds,
    );
    this.thresholds.init();
  }

  private async fetchMedications() {
    const medications = await apiGetPatientsPatientIdMedications({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.medications = new Medications(
      medications.data.medications,
      this.api.amILevel3,
    );
    this.medications.init();
  }

  private async fetchComorbidities() {
    const diags = await apiGetHfComorbiditiesPatientId({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.diags = new Diagnoses(diags.data.diagnoses, this.api.amILevel3);
    this.diags.init();
    await this.fetchDiagnosesOptions();
  }

  private async fetchDiagnosesOptions() {
    const response = await apiGetHfComorbidities({ throwOnError: true });
    this.diagOptions = response.data.comorbidities;
  }

  private async fetchLabs() {
    const labs = await apiGetHfLabDataPatientId({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.labs = new MedData(labs.data.medical_data, this.api.amILevel3);
    this.labs.init();
    this.labsPlot = new MedicalPlot(
      this.labs,
      ["NT-proBNP", "BNP", "urea", "creatinine", "hemoglobin", "hematocrit"],
      "labsPlot",
    );
    this.labsPlot.initPlot();
  }

  private async fetchExams() {
    const exams = await apiGetHfExamsPatientId({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.exams = new MedData(exams.data.medical_data, this.api.amILevel3);
    this.exams.init();
  }

  private async fetchBasicMedData() {
    const basicMed = await apiGetHfBasicMedDataPatientId({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.basicMed = new MedData(basicMed.data.medical_data, this.api.amILevel3);
    this.basicMed.init();
    this.basicMedPlot = new MedicalPlot(
      this.basicMed,
      ["weight", "dry weight", "temperature", "respiratory rate"],
      "basicMedPlot",
    );
    this.basicMedPlot.initPlot();
  }

  private async fetchPpgData() {
    const ppgs = await apiGetPatientsPatientIdPpgs({
      path: { patient_id: this.patientId },
      throwOnError: true,
    });
    this.ppgs = new PPGs(ppgs.data.ppg_data);
    this.ppgs.init();
  }

  private async fetchSchedules() {
    const schedules = await apiGetSchedules({
      query: { patient_ids: [this.patientId], schedule_type: "ppg" },
    });
    this.schedules = new Schedules(schedules.data.schedules);
    this.schedules.init();
  }

  private async fetchPhysicianMessage() {
    this.physicianMessage = (
      await apiGetMessagingPatientId({
        path: { patient_id: this.patientId },
        throwOnError: true,
      })
    ).data;
  }

  private async fetchHeartCore() {
    if (!this.api.amILevel3) {
      return;
    }

    this.heartCore = this.data.heart_core;
    this.heartCore.last_ppg = dateTimeOrNull(this.heartCore.last_ppg, true);
    this.heartCore.reportStart = dateTimeOrNull(
      this.heartCore.report_start_date,
      false,
    );
    this.heartCore.dris = this.heartCore.last_5_dris.map(
      ([datetime, floatVal]) => [
        dateTimeOrNull(datetime),
        parseFloat(floatVal.toFixed(1)),
      ],
    );
  }

  getOptionsForDiag(diagName: string) {
    return this.diagOptions[diagName].reduce((acc, item) => {
      acc[item] = item;
      return acc;
    }, {});
  }

  async redirectToLabelStudio(ppgUuid: string) {
    try {
      const response = await apiGetLabelstudioTaskProjectIdPpgUuid({
        path: { project_id: labelStudioProjectId, ppg_uuid: ppgUuid },
        throwOnError: true,
      });
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error redirecting:", error);
    }
  }
}
