import { PPGRelatedQualityFlag } from "../api";
import { DataModel } from "./DataModel";

export class DerivedData extends DataModel {
  // constants
  algoFlags: Record<PPGRelatedQualityFlag, string> = {
    [PPGRelatedQualityFlag.wrong]: "Wrong",
    [PPGRelatedQualityFlag.ok]: "OK",
    [PPGRelatedQualityFlag.low_ppg_quality]: "Low PPG Quality",
  };
  dataGroups = { hr: "HR", hrv: "HR Variability", spo2: "SpO2" };
  aggTypes = { full: "full", window: "windowed" };
  aggFuncs = {
    mean: "mean",
    median: "median",
    std: "STD",
    min: "min",
    max: "max",
    sdnn: "SDNN",
    sdsd: "SDSD",
    rmssd: "RMSSD",
    pnn50: "pNN50",
    pnn20: "pNN20",
    psd_lf: "low frequency power",
    psd_hf: "high frequency power",
    sample_entropy: "entropy",
  };
  spo2Name = "SpO2: mean";
  hrName = "HR: mean";

  constructor(data) {
    super(data);
    this.sortBy = ["measurement_datetime"];
    this.sortByNames = ["Measured"];
    this.dateTimeFields = ["measurement_datetime"];
    this.initSort = [true];
    this.filteringAttrs = [
      "data_group",
      "rolling",
      "aggregation_function",
      "measurement_type",
      "seerlinq_measurement_quality_flag",
    ];
    this.filteringListAttrs = ["tags"];
    this.defaultFilter = {
      rolling: ["full"],
      aggregation_function: [
        "mean",
        "sdnn",
        "sdsd",
        "pnn50",
        "psd_lf",
        "psd_hf",
        "sample_entropy",
      ],
    };
    this.fieldMapper = { rolling: "Aggregation type" };
  }

  init() {
    this.data = this.data.map((item) => {
      const ppgCond = item["ppg_data"]["measurement_condition"];
      return {
        ...item,
        ["ppg_conditions"]: ppgCond,
      };
    });
    super.init();
  }

  strWindow(window) {
    if (window === null) {
      return "";
    } else {
      const windowStr = window.window_function + ": " + window.length + "s / ";
      if (window.length === window.step) {
        return windowStr + "non-overlapping";
      } else {
        return windowStr + "s step";
      }
    }
  }

  filterSpO2() {
    this.data = this.data.filter((item) => {
      return (
        item.measurement_type === this.spo2Name &&
        item.data_group === "spo2" &&
        item.rolling === "full"
      );
    });
  }

  filterHR() {
    this.data = this.data.filter((item) => {
      return (
        item.measurement_type === this.hrName &&
        item.data_group === "hr" &&
        item.rolling === "full"
      );
    });
  }
}
