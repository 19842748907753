import { AlertType } from "./api";

export const diagConfidence: number[] = [0, 25, 50, 75, 100];
export const medicalOptions: Record<string, string[] | number[]> = {
  // exams basic
  "ECG rhythm": ["SR", "AF", "Paced", "Other"],
  "ECG premature contractions": ["No", "Yes"],
  "leg edema": [0, 1, 2, 3, 4],
  "jugular venous distention": ["No", "Yes"],
  // echo
  "elevated LV filling pressure": ["No", "Yes"],
  AoS: ["None", "Mild", "Moderate", "Severe"],
  AoR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  MR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  MS: ["None", "Mild", "Moderate", "Severe"],
  TR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  PuR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  "B-lines on lung-ultrasound": ["No", "Yes"],
  "pleural effusion": ["None", "Minor", "Major"],
  ascites: ["None", "Minor", "Major"],
  "elevated LV filling pressure final outcome": ["No", "Yes"],
};
export const eventTypes = {
  patient_contact: "Patient contact",
  medical_call: "Medical call",
  medication_change: "Medication change",
  check_up: "Check-up",
  labs: "Labs",
  hospitalization: "Hospitalization",
  death: "Death",
  patient_non_adherence: "Patient non-adherence",
  physician_notified: "Treating physician notified",
};
export const alertTypes: Record<AlertType, string> = {
  [AlertType.low_critical]: "CRITICAL low",
  [AlertType.low_alert]: "Alert low",
  [AlertType.low_warning]: "warning low",
  [AlertType.normal]: "normal",
  [AlertType.high_warning]: "warning high",
  [AlertType.high_alert]: "Alert high",
  [AlertType.high_critical]: "CRITICAL high",
};
