import { For, JSX, Show } from "solid-js";
import { Legend } from "./Legend";
import { RowsSelector } from "./RowsSelector";
import { DataModel } from "../../../dataModels/DataModel";
import { PaginationControl } from "./PaginationControl";

export interface Column<T> {
  header1: JSX.Element;
  header2?: JSX.Element;
  header3?: JSX.Element;
  cell: (item: T, index: number) => JSX.Element;
}

export function Table<T extends object>(props: {
  model: DataModel<T>;
  columns: Array<Column<any> | false | null | undefined>;
  legendExtra?: JSX.Element;
  rowsExtra?: JSX.Element;
}) {
  const columns = props.columns.filter((col): col is Column<T> => !!col);
  const hasAnyHeader2 = columns.some((col) => !!col.header2);
  const hasAnyHeader3 = columns.some((col) => !!col.header3);

  return (
    <div>
      <Legend model={props.model} />

      {props.legendExtra}

      <RowsSelector model={props.model} />

      <div class="table-container">
        <table>
          <thead>
            {/* header level 1 */}
            <tr>
              <For each={columns}>{(col) => <th>{col.header1}</th>}</For>
            </tr>
          </thead>
          <tbody>
            {/* header level 2 */}
            <Show when={hasAnyHeader2}>
              <tr>
                <For each={columns}>{(col) => <td>{col.header2}</td>}</For>
              </tr>
            </Show>

            {/* header level 3 */}
            <Show when={hasAnyHeader3}>
              <tr>
                <For each={columns}>{(col) => <td>{col.header3}</td>}</For>
              </tr>
            </Show>

            {/* rows */}
            <For each={props.model.paginatedData}>
              {(item, index) => (
                <tr>
                  <For each={columns}>
                    {(col) => <td>{col.cell(item, index())}</td>}
                  </For>
                </tr>
              )}
            </For>

            {props.rowsExtra}
          </tbody>
        </table>
      </div>

      <PaginationControl model={props.model} />
    </div>
  );
}
